import { registerApplication, start } from 'single-spa';

import './global-config';
import './styles/main.scss';

const loadRoot = () => import('./root.app.js');

registerApplication(
  'root',
  loadRoot,
  () => true,
  {
    domElementGetter: () => document.getElementById('root')
  }
);

start();
